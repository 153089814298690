<template>
    <div class="DocPage">
        <div class="resultBox ">
            <transition name="van-fade">
                <scroll v-if="!isLoading&&list.length>0" class="wrapper" ref="scroll" :pullDownRefresh="pullDownRefreshObj" :pullUpLoad="pullUpLoadObj" :startY="parseInt(startY)" @pullingDown="onPullingDown" @pullingUp="onPullingUp">
                    <ul class="docList">
                        <template v-for="(item,index) in list">
                            <li class="docItem" :key="index" @click="toDetail(item.cId, item.mailAddress)">
                                <div class="itemTitle">
                                    <div class="left blod">{{item.mailAddress}}</div>
                                    <div class="right">{{item.finishTime}}</div>
                                </div>
                                <div class="itemTitle">
                                    <div class="left">{{item.companyName?item.companyName:''}}</div>
                                    <div class="right red">{{returnReason(item.reason)}}</div>
                                </div>
                                <div class="itemTitle">
                                    <div class="left">{{item.hint}}</div>
                                </div>
                                <div class="itemTitle" style="text-align: right;">
                                    <van-button round size="small" plain @click="toLogUrl(item.logFile)" v-show="item.hasOwnProperty('logFile') && item.logFile != ''">查看日志</van-button>
                                    <van-button round size="small" plain @click.stop="setMarkSolved('markSolved', item.taskId)" v-show="item.status != 1 ">置为已处理</van-button>
                                    <van-button round size="small" plain @click.stop="showSelect(item.taskId)">人工干预</van-button>
                                </div>
                            </li>
                        </template>
                    </ul>
                </scroll>
            </transition>
            <transition name="van-fade">
                <nodata v-if="list.length<=0&&!isLoading"></nodata>
            </transition>
            <transition name="van-fade">
                <loading v-if="isLoading" size="30px"></loading>
            </transition>
        </div>
        <!-- 弹出选项 -->
        <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
    </div>
</template>

<script>
import mixin from '../mixin.js'
import Scroll from '@/components/Scroll/index'
export default {
    name: 'DocPage',
    mixins: [mixin],
    props: {
    },
    data() {
        return {
            pullDownRefresh: true,
            pullDownRefreshThreshold: 60,
            pullDownRefreshStop: 60,
            pullUpLoad: true,
            pullUpLoadThreshold: 0,
            pullUpLoadMoreTxt: '加载更多',
            pullUpLoadNoMoreTxt: '没有更多数据了',
            startY: 0,
            isLoading: false,
            list: [],
            page: {
                pageN: 1,
                pageSize: 10,
                total: 0
            },
            // 本业务相关
            show: false,
            actions: [
                { name: '重启收件', code: 'restart' },
                { name: '切换为初始化', code: 'init' },
                { name: '切换邮件服务器', code: 'switchChannel' }
            ],
            taskId: ''
        }
    },
    computed: {

        pullDownRefreshObj: function () {
            return this.pullDownRefresh ? {
                threshold: parseInt(this.pullDownRefreshThreshold),
                stop: parseInt(this.pullDownRefreshStop)
            } : false
        },
        pullUpLoadObj: function () {
            return this.pullUpLoad ? {
                threshold: parseInt(this.pullUpLoadThreshold),
                txt: { more: this.pullUpLoadMoreTxt, noMore: this.pullUpLoadNoMoreTxt }
            } : false
        }

    },
    created() {
    },
    mounted() {
        this.setMenu()
        this.reGetList()
    },
    methods: {
        toLogUrl(url) {
            window.open(url)
        },
        async setMarkSolved(str, taskId) {
            await this.getIntervene(str, taskId)
            this.isLoading = true
            this.list = await this.getData()
            this.isLoading = false
        },
        returnReason(str) {
            if (str == 900) {
                return '认证失败'
            } else if (str == 700) {
                return '邮局踢出'
            } else if (str == 600) {
                return '连接超时'
            } else if (str == 800) {
                return '异常邮件'
            }
        },
        // 本业务相关
        toDetail(corpId, mailAccount) {
            this.$router.push(
                {
                    path: '/mailmonitor/monitordetail',
                    query: {
                        corpId: corpId, mailAccount: mailAccount
                    }
                }
            )
        },
        showSelect(taskId) {
            this.show = true
            this.taskId = taskId
        },
        onSelect(item) {
            // 点击选项时默认不会关闭菜单，可以手动关闭
            this.show = false
            this.getIntervene(item.code, this.taskId)
        },
        async getIntervene(optCode, taskId) {
            this.isLoading = true
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.mailWatcher_abNormalList
                let params = {
                    optCode: optCode,
                    taskId: taskId
                }
                let res = await this.axios.put(url, params)
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.$toast.success(res.data.msg)
                    this.isLoading = false
                } else {
                    this.isLoading = false
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                this.isLoading = false
                console.log(error)
            }
        },
        // end
        setMenu() {
            this.Global.utils.rightMenu.clearMenu()
        },
        async reGetList() {
            this.page.pageN = 1
            this.isLoading = true
            this.list = await this.getData()
            this.isLoading = false
        },
        async getData() {
            let listArr = []
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.mailWatcher_abNormalList
                let params = {
                    pageN: this.page.pageN,
                    pageSize: this.page.pageSize
                }
                let res = await this.axios.get(url, { params })
                if (res.data.code.toString() == this.Global.config.RES_OK && this.Global.utils.isObject(res.data.data)) {
                    listArr = res.data.data.dataList
                    this.page.total = res.data.data.totalNum
                } else {
                    listArr = []
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                console.log(error)
                listArr = []
            }
            return listArr
        },
        async onPullingDown() {
            this.page.pageN = 1
            this.list = await this.getData()
            if (this._isDestroyed) {
                return
            }
            this.$nextTick(() => {
                this.$refs.scroll.forceUpdate()
            })
        },
        async onPullingUp() {
            if (this.list.length >= this.page.total) {
                this.$refs.scroll.forceUpdate(false)
                return
            }
            if (this.list.length < this.page.total) {
                this.page.pageN += 1
                let list = await this.getData()
                this.list = this.list.concat(list)
                this.$nextTick(() => {
                    if (this.list.length >= this.page.total) {
                        this.$refs.scroll.forceUpdate(false)
                    } else {
                        this.$refs.scroll.forceUpdate(true)
                    }
                })
            }
        },

        rebuildScroll() {
            this.$nextTick(() => {
                this.$refs.scroll.destroy()
                this.$refs.scroll.initScroll()
            })
        },
        /* 路由离开时通过父组件调用 */
        beforeLeave(to, from, next) {
            // const flag = this.Global.utils.chosen.close()
            // const flag2 = this.Global.utils.actionSheet.close()
            // const flag3 = this.Global.utils.prompt.close()
            // if (flag || flag2 || flag3) {
            //     next(false)
            // } else {
            //     let len = this.folderNavs.length
            //     const RouteWhiteList = ['MoveFile', 'NewMail']
            //     if (len >= 2 && !RouteWhiteList.includes(to.name)) {
            //         this.folderNavs.splice(len - 1, 1)
            //         this.setTitle(this.folderNavs[this.folderNavs.length - 1].name)
            //         this.reGetList()
            //         next(false)
            //     } else {
            //         next()
            //     }
            // }
        }
    },

    watch: {
        pullDownRefreshObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullDown()
                } else {
                    scroll.closePullDown()
                }
            },
            deep: true
        },
        pullUpLoadObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullUp()
                } else {
                    scroll.closePullUp()
                }
            },
            deep: true
        },
        startY() {
            this.rebuildScroll()
        }
    },

    components: {
        'scroll': Scroll
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
