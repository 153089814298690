<template>
    <div class="DocumentKnowledgeBaseBox">
        <doc-page ref='docPage' :moduleCode='moduleCode' :rootFolderId="rootFolderId" :rootFolderName="rootFolderName" :folderSource="folderSource" class="DocumentKnowledgeBase"></doc-page>
    </div>
</template>

<script>
import DocPage from '../Vue/DocPage/index'
import titleMixin from '@/mixin/title'
export default {
    name: 'RealAnomaly',
    mixins: [titleMixin],
    title: '实时感知异常',
    data() {
        return {
            moduleCode: 'DC001',
            rootFolderId: 1,
            rootFolderName: '实时感知异常',
            folderSource: 1
        }
    },
    created() { },

    mounted() { },
    methods: {},
    // beforeRouteLeave(to, from, next) {
    //     this.$refs.docPage.beforeLeave(to, from, next)
    // },
    watch: {
        $route(to, from) {
            if (this.$route.path == '/mailmonitor/realanomaly') {
                this.$refs.docPage.setMenu()
                this.$refs.docPage.reGetList()
            }
        }
    },
    components: {
        'doc-page': DocPage
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
